<div [class]="body.primary.padding_bottom ? 'mb-5 mb-lg-6' : ''">
    <div [class]="body.primary.pink_background ? 'background-pink' : ''">
        <div class="container" [ngClass]="reduceMarginTop ? 'pt-4' : 'pt-5 pt-lg-6'" appAos>
            <div class="row">
                @if (body.primary.image?.url) {
                <div class="col-lg-6" [class]="body.primary.image?.url ? 'order-lg-1' : ''">
                    <img [src]="body.primary.image.url" [alt]="body.primary.image.alt" class="w-100 image-mask mask-02" />
                </div>
                }
                <div [class]="body.primary.image?.url ? 'col-lg-6 order-lg-0 mt-5 mt-lg-0' : 'col-lg-10 pe-4'">
                    <p class="heading" [class.color-white]="body.primary.pink_background">{{ body.primary.heading }}</p>
                    <div [innerHTML]="body.primary.title | renderHtml : htmlSerializer"></div>
                    <div [innerHTML]="body.primary.description | renderHtml"></div>
                </div>
                @if (!body.primary.image?.url) {
                <div class="col-auto d-none d-lg-flex justify-content-end pt-5">
                    <img
                        [src]="body.primary.pink_background ? '/assets/images/dots-plus-white.svg' : '/assets/images/dots-plus.svg'"
                        alt="Design-Element"
                        class="icon-big shape"
                    />
                </div>
                }
            </div>
        </div>
    </div>
</div>
