<div class="container">
    <div class="row gx-lg-5">
        @for (item of body.items; track item) {
        <div class="mb-5 col-lg-4" appAos>
            @switch (body.primary.mode) { @case (ThreeColumnsMode.Icon) {
            <div class="lotti-wrapper">
                @if (isBrowser) {
                <ng-lottie [options]="{ path: item.lottie_file.url }" class=""></ng-lottie>
                }
            </div>
            } @case (ThreeColumnsMode.Image) {
            <img [src]="item.image.url" [alt]="item.image.alt" class="mb-5 w-100" />
            } }
            <p class="heading">{{ item.heading }}</p>
            <div [innerHTML]="item.title | renderHtml : htmlSerializer"></div>
            <div [innerHTML]="item.description | renderHtml : htmlSerializer"></div>
            @if (item.video) {
            <button class="btn btn-black mt-4" (click)="openVideoModal(item)">
                Video ansehen
            </button>
            }
        </div>
        }
    </div>
</div>
