import { isPlatformBrowser } from '@angular/common';
import { Component, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ImageField, KeyTextField, LinkField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LottieComponent } from 'ngx-lottie';
import { AosDirective } from '../../aos/aos.directive';
import { VideoModalComponent } from '../../video-modal/video-modal.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-three-columns-slice',
    templateUrl: './three-columns-slice.component.html',
    styleUrls: ['./three-columns-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, LottieComponent, RenderHtmlPipe]
})
export class ThreeColumnsSliceComponent implements OnInit {
    ThreeColumnsMode = ThreeColumnsMode;

    @Input() body!: ThreeColumnsSlice;
    private platformId = inject<Object>(PLATFORM_ID);
    isBrowser = isPlatformBrowser(this.platformId);

    constructor(private modalService: BsModalService) {}

    ngOnInit(): void {}

    openVideoModal(item: ThreeColumnItem) {
        this.modalService.show(VideoModalComponent, {
            initialState: { video: item.video, thumbnail: item.thumbnail },
            class: 'modal-lg'
        });
    }

    htmlSerializer: HTMLMapSerializer = {
        list: ({ children }) => '<ul class="list-unstyled">' + children + '</ul>',
        listItem: ({ children }) => '<li class="mt-2 list-item-point">' + children + '</li>',
        heading3: ({ children }) =>
            `<h3 class=\"${this.body.primary.mode === ThreeColumnsMode.Headline ? 'text-size-xl mt-5 mt-lg-6' : ''}\">` + children + '</h3>'
    };
}

export type ThreeColumnsSlice = Slice<
    '3_spalten',
    {
        icon_mode: boolean;
        mode: ThreeColumnsMode;
    },
    ThreeColumnItem
>;

export type ThreeColumnItem = {
    image: ImageField;
    heading: KeyTextField;
    title: TitleField;
    description: RichTextField;
    lottie_file: LinkField;
    video: KeyTextField;
    thumbnail: ImageField;
};

enum ThreeColumnsMode {
    Image = 'Bild',
    Icon = 'Icon',
    Headline = 'Große Überschrift'
}
